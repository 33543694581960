import { useMemo } from 'react';

import {
  ProductDetailsApiDocument,
  ProductDetailsValue,
} from '@hultafors/shared/types';

import {
  AppLinksBlockFragment,
  CategoryFeatureFragment,
} from '@hultafors/hellberg/types';

import { AppLinksBlock } from '../app-links-block/app-links-block';
import { Features } from '../Features/Features';
import { ProductInformationTabs } from '../ProductInformationTabs/ProductInformationTabs';

import {
  FeatureList,
  ProductDetailInformationStyled,
  RetailersLink,
} from './ProductDetailInformation.styled';

interface ProductDetailInformationProps {
  addToShoppingListButtonLabel?: string;
  overviewLabel: string;
  documentsLabel: string;
  detailsLabel: string;
  intro: string;
  documents: ProductDetailsApiDocument[];
  detailsInfo: string;
  features: ProductDetailsValue[];
  featuresLabel: string;
  productForStorage: any;
  usp: string[];
  appInformation: AppLinksBlockFragment | null;
  retailersLabel: string;
}

export const ProductDetailInformation: React.FC<
  ProductDetailInformationProps
> = ({
  appInformation,
  detailsInfo,
  detailsLabel,
  documents,
  documentsLabel,
  features: featuresInput,
  featuresLabel,
  intro,
  overviewLabel,
  productForStorage,
  usp,
  retailersLabel,
}) => {
  const featuresFilter = (feature: ProductDetailsValue) => {
    if (
      !feature.text
      || (typeof feature.text === 'string' && feature.text.trim().length === 0)
      || feature.image === null
      || feature.image === undefined
    ) {
      return null;
    }

    return feature;
  };

  const features: CategoryFeatureFragment[] = useMemo(() => {
    return (
      featuresInput.filter(featuresFilter).map((feature) => {
        return {
          name: feature.text,
          icon: {
            url: feature?.image?.url || '',
            alt: feature?.image?.description || '',
            tags: [],
            format: feature?.image?.format || '',
          },
        };
      }) || []
    );
  }, [featuresInput]);

  return (
    <ProductDetailInformationStyled>
      {!!usp?.length && (
        <FeatureList>
          {usp.filter(Boolean).map((item, i) => (
            <li key={`uspKey${i}`}>{item}</li>
          ))}
        </FeatureList>
      )}

      {retailersLabel && (
        <span className="ChooseSizeButton">
          <RetailersLink href="/retailers">{retailersLabel}</RetailersLink>
        </span>
      )}
      <ProductInformationTabs
        overviewLabel={overviewLabel}
        documentsLabel={documentsLabel}
        detailsLabel={detailsLabel}
        intro={intro}
        detailsInfo={detailsInfo}
        documents={documents}
      />
      {appInformation && (
        <AppLinksBlock
          {...appInformation}
          styles={{
            maxInlineSize: 'unset',
            inlineSize: '100%',
            marginBlockEnd: '48px',
          }}
        />
      )}
      {features.length > 0 && (
        <Features title={featuresLabel} features={features} />
      )}
    </ProductDetailInformationStyled>
  );
};
