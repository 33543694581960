import { useState } from 'react';

import { ProductDetailsApiDocument } from '@hultafors/shared/types';

import { DownloadFileBox } from '../DownloadFileBox/DownloadFileBox';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  DetailInfo,
  OverviewText,
  ProductInformationTabsStyled,
  Tab,
  Tabs,
} from './ProductInformationTabs.styled';

interface ProductInformationTabsProps {
  overviewLabel?: string;
  documentsLabel?: string;
  detailsLabel?: string;
  intro?: string;
  documents: ProductDetailsApiDocument[];
  detailsInfo?: string;
}

export const ProductInformationTabs: React.FC<ProductInformationTabsProps> = ({
  overviewLabel,
  intro,
  documentsLabel,
  documents,
  detailsLabel,
  detailsInfo,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const detailsArray = detailsInfo?.split(/\r|\n/);

  const shouldRenderTabs = () => {
    let count = 0;
    if (intro) {
      count++;
    }
    if (haveDocuments()) {
      count++;
    }
    if (detailsInfo) {
      count++;
    }
    return count > 1;
  };

  const renderTabs = () => {
    if (!shouldRenderTabs()) return null;
    return (
      <Tabs>
        {intro && (
          <Tab $active={activeTab === 1} onClick={() => setActiveTab(1)}>
            <Paragraph styleType="body14">
              {' '}
              {overviewLabel}
            </Paragraph>
          </Tab>
        )}
        {haveDocuments() && (
          <Tab $active={activeTab === 2} onClick={() => setActiveTab(2)}>
            <Paragraph styleType="body14">{documentsLabel}</Paragraph>
          </Tab>
        )}
        {detailsInfo && (
          <Tab $active={activeTab === 3} onClick={() => setActiveTab(3)}>
            <Paragraph styleType="body14">{detailsLabel}</Paragraph>
          </Tab>
        )}
      </Tabs>
    );
  };

  const haveDocuments = () => {
    return documents && documents.length > 0;
  };
  const renderDocuments = () => {
    return documents.map((document, i) => {
      return (
        <DownloadFileBox
          key={`document${i}`}
          url={document.url}
          label={document.name}
          size={document.size}
          filetype={document.filetype}
        />
      );
    });
  };

  const renderTabInfo = () => {
    return (
      <>
        {intro && activeTab === 1 && <OverviewText>{intro}</OverviewText>}
        {haveDocuments() && activeTab === 2 && renderDocuments()}

        {detailsArray && detailsArray.length && activeTab === 3 && (
          <DetailInfo>
            {detailsArray.map((item, index) => (
              <li key={`Detail-Key-${index}`}>{item}</li>
            ))}
          </DetailInfo>
        )}
      </>
    );
  };

  return (
    <ProductInformationTabsStyled>
      {renderTabs()}
      {renderTabInfo()}
    </ProductInformationTabsStyled>
  );
};
