import { useGlobal } from '@hultafors/hellberg/hooks';

import Globe from '../../svg/globe.svg';
import Hamburger from '../../svg/hamburger.svg';
import LogoFull from '../../svg/logo_full.svg';
import { IconButtonWrapper } from '../IconButtonWrapper/IconButtonWrapper';
import { OpenSettings } from '../Nav/Nav';
import { NavItem } from '../NavItem/NavItem';

import {
  Logo,
  MenuActions,
  MenuToggler,
  NavigationMobileStyled,
} from './NavigationMobile.styled';

interface NavigationMobileProps {
  setIsOpen(value: OpenSettings): void;
  defaultOpenSettings: OpenSettings;
  toggleFavoritesMenu(): void;
  toggleCartMenu(): void;
  toggleMenu(): void;
  toggleMarketPicker(): void;
}

export const NavigationMobile: React.FC<NavigationMobileProps> = ({
  setIsOpen,
  defaultOpenSettings,
  toggleFavoritesMenu,
  toggleCartMenu,
  toggleMenu,
  toggleMarketPicker,
}) => {
  const { shared } = useGlobal();
  return (
    <NavigationMobileStyled>
      <MenuToggler onClick={toggleMenu}>
        <Hamburger
          aria-hidden={true}
          focusable={false}
          width={24}
          height={24}
        />
      </MenuToggler>
      <Logo
        href="/"
        aria-label="Hellberg Safety"
        onClick={() => setIsOpen({ ...defaultOpenSettings })}
      >
        <LogoFull
          aria-hidden={true}
          focusable={false}
          width={121}
          height={24}
        />
      </Logo>

      <MenuActions>
        <IconButtonWrapper name="MarketPicker" onClick={toggleMarketPicker}>
          <Globe aria-hidden={true} focusable={false} width={24} height={24} />
        </IconButtonWrapper>
        {!!shared?.urlToPartnerPortal && (
          <NavItem
            toggle={() => null}
            urlToPartnerPortal={shared.urlToPartnerPortal}
          />
        )}
      </MenuActions>
    </NavigationMobileStyled>
  );
};
